import { useState } from 'react'

import ContinueToPaymentButton from '~/modules/signup/components/ContinueToPaymentButton'
import RadioInput from '~/components/forms/RadioInput'
import TextInput from '~/components/forms/TextInput'
import useRootData from '~/hooks/useRootData'
import { SubscriptionType, Plan } from '~/types/subscriptions'
import { CollectionMethod } from '~/modules/signup/types/collection-method'
import { SignupStatus } from '~/modules/signup/types/signup-status'
import { pricing } from '~/config/pricing'

type RenewPlanPanelProps = {
  showHeading?: boolean
  onComplete?: () => void
}
export const RenewSubscriptionPanel = ({ showHeading = true, onComplete }: RenewPlanPanelProps) => {
  const { currentCustomer, currentCustomerAccessPermission } = useRootData() || {}
  const { plan, subscriptionUserSeats, collectionMethod, signupStatus } = currentCustomer ?? {}
  const { isCustomerAdmin } = currentCustomerAccessPermission ?? {}

  const subscriptionType = currentCustomer?.plan?.startsWith('school')
    ? SubscriptionType.SCHOOL
    : SubscriptionType.INDIVIDUAL

  // Track states for the form
  const [selectedPlan, setSelectedPlan] = useState<Plan>(plan as Plan)
  const [selectedSeats, setSelectedSeats] = useState<number>(subscriptionUserSeats || 1)

  if (!currentCustomer || !isCustomerAdmin) {
    return null
  }

  const willPayByInvoice = collectionMethod === CollectionMethod.SEND_INVOICE

  if (signupStatus && signupStatus !== SignupStatus.PAYMENT_AND_BILLING_DETAILS_ENTERED) {
    return null
  }

  return (
    <div className="flex flex-col space-y-8">
      {showHeading && <h2>Renew your Cub Club subscription</h2>}

      <div className="space-y-4">
        {subscriptionType === SubscriptionType.SCHOOL ? (
          <div>
            <div>
              <label>Number of User Seats:</label>
              <TextInput
                name="subscriptionUserSeats"
                type="integer"
                className="max-w-[100px]"
                defaultValue={subscriptionUserSeats}
                onChange={e => setSelectedSeats(Number(e.target.value))}
              />
            </div>
            <div className="mt-4 space-y-1">
              <div>
                Price per user: <strong>A${pricing[Plan.SCHOOL_ANNUAL]}</strong> per year
              </div>
              <div>
                Total price: <strong>A${pricing[Plan.SCHOOL_ANNUAL] * selectedSeats}</strong> per year
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            <RadioInput
              value={Plan.INDIVIDUAL_MONTHLY}
              label={
                <>
                  Monthly - <strong>A${pricing[Plan.INDIVIDUAL_MONTHLY]}</strong> per month
                </>
              }
              name="plan"
              checked={selectedPlan === Plan.INDIVIDUAL_MONTHLY}
              onChange={e => setSelectedPlan(e.target.value as Plan)}
            />
            <RadioInput
              value={Plan.INDIVIDUAL_ANNUAL}
              label={
                <>
                  Annual - <strong>A${pricing[Plan.INDIVIDUAL_ANNUAL]} per year</strong> (Save 17%!)
                </>
              }
              name="plan"
              checked={selectedPlan === Plan.INDIVIDUAL_ANNUAL}
              onChange={e => setSelectedPlan(e.target.value as Plan)}
            />
          </div>
        )}
      </div>

      {willPayByInvoice && (
        <p className="text-sm text-gray-600 dark:text-gray-300">
          Since you are set up for <strong>invoice payments</strong>, your subscription will be created immediately and
          you will receive a separate invoice for payment to your billing email.
        </p>
      )}

      <ContinueToPaymentButton
        plan={subscriptionType === 'school' ? Plan.SCHOOL_ANNUAL : selectedPlan}
        subscriptionUserSeats={subscriptionType === 'school' ? selectedSeats : undefined}
        text={willPayByInvoice ? 'Start new subscription' : undefined}
        onComplete={onComplete}
      />
    </div>
  )
}

export default RenewSubscriptionPanel
